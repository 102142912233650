<template>
  <div>
  <CCard>
   
  <CCardBody>
    <div class="clearfix">
      <button type="button" class="btn btn-primary float-xl-right" @click="navToNewSymptoms">New Sub Section</button>
    </div>

    <CDataTable :items="items"  
    :fields="fields"
      striped
      clickable-rows
      column-filter
       table-filter
        items-per-page-select
      :items-per-page="20"
       sorter
       ref="vuetable"
      pagination
       :responsive="true"
       >

        <template #optionType-filter>
          <select
            class="form-control form-control-sm"
            @input="$refs.vuetable.columnFilterEvent('optionType', $event.target.value, 'input')"
          >
            <option value selected="selected">Any</option>
            <option value="1"> Input Condition </option>
            <option value="2">Input Reading</option>
          </select>
        </template>
       
      <template #index="data">
       <td>
         {{data.index +1}}
         </td>
     </template>

      <template #createdTime="{item}">
        <td class='font-weight-bold'
          v-if="item.createdTime"
        >{{ item.createdTime |dateParse()| dateFormat("MMM D, YYYY hh:mm A") }}</td>
        <td v-else>--</td>
      </template>
     <!-- <template #checkOptionName="{item}">
        <td class='font-weight-bold'
          v-if="item.checkOptionName"
        >{{ item.checkOptionName }}</td>
        <td v-else>--</td>
      </template> -->
        <template #optionType="{item}">
        <td class='font-weight-bold'
          v-if="item.optionType == 1">
            Input Condition  </td>
          <td class='font-weight-bold' v-else>Input Reading </td>
       
        
      </template>  

     <!-- <template #checkOptionName="{item}">
        
        <td class='font-weight-bold'
          v-if="item.optionType == 2"
        >{{ item.green || item.red || item.amber}}</td>
        <td class='font-weight-bold' v-else>{{ item.checkOptionName }}</td>
     </template> -->
     <template #green="{item}">
        
        <td class='font-weight-bold'
          v-if="item.optionType == 2"
        >{{ item.green}}</td>
        <td class='font-weight-bold' v-else>Ok</td>
     </template>
      <!-- <template #amber="{item}">
        
        <td class='font-weight-bold'
          v-if="item.optionType == 2"
        >{{ item.amber}}</td>
        <td class='font-weight-bold' v-else>{{ item.checkOptionName}}</td>
     </template> -->
      <template #red="{item}">
        
        <td class='font-weight-bold'
          v-if="item.optionType == 2"
        >{{ item.red}}</td>
        <td class='font-weight-bold' v-else>Not Ok</td>
     </template>
        <template #show_details="{item}">
        <td class="py-2">
        <CDropdown
                  togglerText="Actions"
                  color="info"
                >
                 
             <CDropdownItem :to="{name: 'SubSection', params: {subSectionId: item.subSectionId, mode: 'view'}}">View</CDropdownItem>
                <CDropdownItem :to="{name: 'SubSectionInputFields', params: {subSectionId: item.subSectionId}}">Input Fields</CDropdownItem> 
                 <ConfirmationModal
                      :parent="$refs.modalArea"
                      :description="'Are you sure to Delete'"
                      @on:ok="deleteSubSection(item.subSectionId)"
                    ></ConfirmationModal> 

                </CDropdown>         
        </td>
      </template>
      </CDataTable>

  </CCardBody>
  <div ref="modalArea"></div>
  <CCardFooter align="right">

            <CButton class="mr-2" type="Cancel" size="sm" color="danger"  @click="cancel()">
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
 </CCard>
 </div>
</template>

<script>
const fields = [
  'index',
   { key: "subSectionTitle",label: "Sub Section Title", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "priority", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "info", _classes: 'font-weight-bold', _style: "min-width:200px" },
    // { key: "red", _classes: 'font-weight-bold', _style: "min-width:200px" },
  { key: "createdTime",label: "Created Date & Time", _classes: 'font-weight-bold', _style: "min-width:200px" },
   { key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
  
];
import ConfirmationModal from "../../containers/ConfirmationModal";
export default {
  name: "SubSections",
  components: {
    ConfirmationModal,
  },
  data() {
    return {
      items: [],
      fields,
      details: [],
      collapseDuration: 0,
    };
  },
  methods: {
    getSubsections() {
     this.apiGetSecure(process.env.VUE_APP_API_HOST + "/emapp/web/secure/workPermit/sections/subSection/"+this.$route.params.sectionId)
        .then((response) => response.json())
        .then((data) => (this.items = data));
    },
   
     cancel() {
   
         this.$router.push({ name: 'Sections',params: {permitFormId: this.$route.params.permitFormId } })
     // window.history.back();
   },
    // deleteSubSections(subSectionId) {
    //   console.log("delete called with", subSectionId);
    //  this.apiGetSecure(
    //     process.env.VUE_APP_API_HOST +
    //       "/emapp/web/secure/delete/workpermit/section/subSections/"+ subSectionId
    //   )
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.getSubsections();
    //          this.$nextTick(() => {
    //         //  this.$refs.vuetable.refresh();
    //        });
      
    //     });
    // },

    deleteSubSection(subSectionId) {
      console.log("delete called with", subSectionId);
     this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/delete/WorkPermit/sections/subSection/"+ subSectionId
      )
        .then((response) => response.json())
        .then((data) => {
          this.getSubsections();
             this.$nextTick(() => {
            //  this.$refs.vuetable.refresh();
           });
         window.location.reload();
        });
    },
    
    navToNewSymptoms(){
       this.$router.push({ name: "SubSection", params: {checkId: this.$route.params.checkId, mode: "new" } } );
    }
  },
  mounted() {
    this.getSubsections();
  },
};
</script>